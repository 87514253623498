import React from "react"
import * as d3 from "d3"
import { legendColor } from "d3-svg-legend"
// import hk18DistrictGeoJson from "./hk18districts.json"
import id33DistrictGeoJson from "./id_city_name.json"
import { feature } from "topojson-client"

const colorList = [
  "#f7f0f0",
  "#edd4cc",
  "#ffad91",
  "#ec7d58",
  "#b53232",
  // "#b53232",
  // "#ec7d58",
  // "#9e2527",
  // "#5c0608",
]

// eslint-disable-next-line no-unused-vars
const legendColumn = container => scale => {
  const legend = legendColor
    .apply(d3)
    .labelFormat(d3.format("d"))
    .labelDelimiter("-")
    .scale(scale)
    .shapeWidth(12)
    .shapeHeight(12)
    .labels(({ i, generatedLabels, range }) => {
      const oriLabel = generatedLabels[i]
      const [start, end] = oriLabel.match(/\d+/g)
      return `${i === 0 ? +start + 1 : start} - ${
        i < generatedLabels.length - 1 ? +end - 1 : end
      }`
    })

  const svg = d3.select(container)
  svg.append("g").attr("class", "legend")

  svg.select(".legend").call(legend)
  const { width, height } = svg
    .select(".legend")
    .node()
    .getBBox()
  svg.attr("width", width)
  svg.attr("height", height)
}

const dimension = (window && window.innerWidth) || 500

export class HK18DistrictChart extends React.Component {
  static defaultProps = {
    districtNodeDrawer: () => undefined,
    getDescriptionByDistrictName: () => "",
    legendTitle: null,
    legendShapeSize: 12,
    getColorer: ele => d3.scaleQuantize(ele.props.scale, colorList),
  }

  appendLegend = scale => {
    const legendMaker = legendColor
      .apply(d3)
      .labelFormat(d3.format("d"))
      .labelDelimiter("-")
      .scale(scale)
      .shapeWidth(this.props.legendShapeSize)
      .shapeHeight(this.props.legendShapeSize)
      .labels(({ i, generatedLabels, range }) => {
        const oriLabel = generatedLabels[i]
        const [start, end] = oriLabel.match(/\d+/g)
        return `${i === 0 ? +start + 1 : start} - ${
          i < generatedLabels.length - 1 ? +end - 1 : end
        }`
      })
    const svg = d3.select(this.legendContainer)
    svg.append("g").attr("class", "legend")

    svg.select(".legend").call(legendMaker)
    const { width, height } = svg
      .select(".legend")
      .node()
      .getBBox()
    svg.attr("width", width)
    svg.attr("height", height)
  }
  updateGraph() {
    const svg = d3.select(this.svgContainer)
    const data = id33DistrictGeoJson
    const {
      objects: { states_provinces: cities },
    } = id33DistrictGeoJson
    // const { objects: { subunits: map_unit } } = id33DistrictGeoJson
    const mapFeatureCollection = feature(data, cities)

    const projection = d3.geoMercator().fitExtent(
      [
        [0, 0],
        [dimension, dimension],
      ],
      mapFeatureCollection
    )
    const geoGenerator = d3.geoPath().projection(projection)

    const color = this.props.getColorer(this)
    const getDescriptionByDistrictName = this.props.getDescriptionByDistrictName
    const tooltip = this.tooltip
    const w = dimension
    const h = dimension
    let centered
    const onClick = datum => {
      const g_id = datum.properties.name.split(" ").join("_")
      const view = d3.select(`#${g_id}`)
      const bound = view.node().getBBox()

      console.log("w_selection", datum, view, view.node().getBBox())
      console.log("w_centroid", geoGenerator.centroid(datum))
      const centroid = geoGenerator.centroid(datum)
      const targetElement = [
        d3.event.offsetX,
        d3.event.offsetY,
        Math.max(bound.width, bound.height),
      ]

      let k = 1
      let translate0 = [0, 0]
      let translate1 = [0, 0]

      if (centered === datum) {
        centered = null
        k = 1
      } else {
        centered = datum
        translate0 = [w / 2, h / 2]
        translate1 = [-centroid[0], -centroid[1]]
        k = Math.min(w, h) / (targetElement[2] * 1.8)
      }

      view
        .select("path")
        .transition()
        .duration(750)
        .attr("stroke-width", "0.2px")
      // narrow stroke-width after clicking
      const view2 = d3.select("#map-view")
      view2
        .transition()
        .attr(
          "transform",
          `translate(${translate0}) scale(${k}) translate(${translate1})`
        )
      // computer graphics. T R S sequence
    }
    const d3DistrictPathNode = svg
      .append("g")
      .attr("id", "map-view")
      .selectAll("path")
      .remove()
      .data(mapFeatureCollection.features)
      .enter()
      .append("g")
      .attr("class", "id-map-province")
      .attr("data-province-name", datum => datum.properties.name)
      .attr("id", datum =>
        datum.properties.name
          ? datum.properties.name.split(" ").join("_")
          : null
      )
      .append("path")
      .attr("fill", datum => {
        const count = this.props.getDataByDistrictName(datum.properties.name)
        return count === 0 ? "#FFFFFF" : color(count)
      })
      .attr("stroke", "#d6d6d6")
      .attr("stroke-width", "1px")
      .attr("d", geoGenerator)
      .on("mouseover", function mouseoverHandler(datum) {
        d3.select(this).attr("stroke", "#b5b5b5")
        if (datum.properties.name === "Jakarta") {
          d3.select(this).attr("stroke-width", "0.2px")
        }
        tooltip.style("visibility", "visible")
        tooltip.text(getDescriptionByDistrictName(datum.properties.name))
      })
      .on("mousemove", function(datum) {
        const event = d3.event
        tooltip
          .style("top", event.pageY + 10 + "px")
          .style("left", event.pageX + 10 + "px")
      })
      .on("mouseout", function mouseoutHandler() {
        d3.select(this).attr("stroke", "#d6d6d6")
        tooltip.style("visibility", "hidden")
      })
      .on("click", onClick)

    this.props.districtNodeDrawer(d3DistrictPathNode)
    this.appendLegend(color)
  }
  initSimpleTooltip() {
    this.tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "hk-district-map-tooltip")
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .style("background", "#333")
      .style("color", "white")
      .style("padding", "10px")
  }
  componentDidMount() {
    this.initSimpleTooltip()
    this.updateGraph()
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.entries !== this.props.entries && this.svgContainer)
      this.updateGraph()
  }
  componentWillUnmount() {
    this.tooltip.remove()
  }
  render() {
    return (
      <div
        ref={el => (this.graphContainer = el)}
        style={{ position: "relative" }}
      >
        <svg
          ref={el => (this.svgContainer = el)}
          viewBox={`0 0 ${dimension} ${dimension}`}
          xmlns="http://www.w3.org/2000/svg"
        ></svg>
        <div
          style={{
            position: "absolutfe",
            pointerEvents: "none",
            fontSize: 12,
            bottom: "5%",
            right: 0,
            backgroundColor: "rgba(255,255,255,0.3)",
            padding: "5px 5px 0px",
          }}
        >
          {this.props.legendTitle}
          <svg
            ref={el => (this.legendContainer = el)}
            xmlns="http://www.w3.org/2000/svg"
            width="100"
          ></svg>
        </div>
      </div>
    )
  }
}
export default HK18DistrictChart
